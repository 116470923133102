import { useLayoutEffect, useState } from "react";
import HTMLReactParser from "html-react-parser";
import { decodeHTML } from "entities";

const isBetween = (value, floor, ceil) => value >= floor && value <= ceil;

const clamp = (value) => Math.max(0, value);

export const useScrollspy = (menus, offset, defaultValue, elementKey) => {
	const [activeId, setActiveId] = useState(defaultValue);

	useLayoutEffect(() => {
		const listener = () => {
			const scroll = window.scrollY;

			const position = menus
				.map((menu) => {
					const element = document.getElementById(menu[elementKey]);

					if (!element)
						return { [elementKey]: menu[elementKey], top: -1, bottom: -1 };

					const rect = element.getBoundingClientRect();
					const top = clamp(rect.top + scroll - offset);
					const bottom = clamp(rect.bottom + scroll - offset);

					return { [elementKey]: menu[elementKey], top, bottom };
				})
				.find(({ top, bottom }) => isBetween(scroll, top, bottom));

			setActiveId(position?.[elementKey] || defaultValue);
		};

		listener();

		window.addEventListener("resize", listener);
		window.addEventListener("scroll", listener);

		return () => {
			window.removeEventListener("resize", listener);
			window.removeEventListener("scroll", listener);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menus, offset]);

	return activeId;
};

export const getFormattedIndex = (num) => {
	return num.toString().padStart(2, "0");
};

export const linkActions = {
	REDIRECT: "Redirect",
	CLICK: "Popup",
};

export const parseHtml = (htmlString = "", checkP = false) => {
	if (checkP && !htmlString.startsWith("<p>") && !htmlString.endsWith("</p>")) {
		return <p>{HTMLReactParser(htmlString)}</p>;
	}
	return htmlString ? HTMLReactParser(htmlString) : "";
};

export const acceptedFileTypes = [
	"image/jpeg",
	"application/pdf",
	"application/msword",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const decodeHTMLEntities = (str) => {
	const element = decodeHTML(str);
	return element;
};

export const useCheckYOffset = (
	pageOffSet = 0,
	eleClass = "",
	operator = ">"
) => {
	const [elementClass, setElementClass] = useState("");

	useLayoutEffect(() => {
		const listener = () => {
			const scroll = window.pageYOffset;

			if (eval(`${scroll} ${operator} ${pageOffSet}`)) {
				setElementClass(eleClass);
			} else {
				setElementClass("");
			}
		};
		listener();

		window.addEventListener("scroll", listener);
		return () => {
			window.removeEventListener("scroll", listener);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return elementClass;
};

export const PHONE_REGEX = /^[0-9+ ]{0,14}$/;

export const SLIDER_STEPS = 500;

export const scrollElementIntoView = (id) => {
	document.getElementById(id)?.scrollIntoView();
};

export const toWebp = (imgURL) => {
	if (typeof imgURL === "string") {
		const isSvg = imgURL?.toLowerCase().endsWith(".svg");
		if (!isSvg) return imgURL?.replace("uploads", "uploads/f_webp");
		return imgURL;
	}
	return imgURL;
};

export const getCookie = (cname) => {
	if (cname) {
		let name = cname + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == " ") {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
	}
	return "";
};

export const getHref = (eleLink = "/") => {
	if (!!eleLink) {
		if (eleLink?.charAt(0) === "/") {
			return eleLink;
		}
		return "/" + eleLink;
	}
	return "/#";
};


export const parseHtml1 = (htmlString = "", checkP = false) => {
	if (checkP && !htmlString.startsWith("<p>") && !htmlString.endsWith("</p>")) {
		return <p>{HTMLReactParser(htmlString)}</p>;
	}
	return htmlString ? HTMLReactParser(htmlString) : "";
};
