import Head from "next/head";
import { decodeHTMLEntities } from "../../utils/common";
import { useRouter } from "next/router";

const commonMeta = [
  {
    name: "description",
    content: "metaDescription",
  },
  {
    name: "keywords",
    content: "keywords",
  },
  {
    name: "og:url",
    content: "canonicalURL",
  },
  {
    name: "og:title",
    content: "metaDescription",
  },
  {
    name: "og:description",
    content: "metaDescription",
  },
  {
    name: "twitter:url",
    content: "canonicalURL",
  },
  {
    name: "twitter:title",
    content: "metaDescription",
  },
  {
    name: "twitter:description",
    content: "metaDescription",
  },
];
let resourceUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;

const HocHead = ({ children, seo, type = null }) => {
  const router = useRouter();
  const canonicalURL = router.asPath;

  const formatJson = (json) => {
    return {
      __html: `${JSON.stringify(json, null, 2)}`,
    };
  };

  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      {!!seo?.metaTitle ? (
        <title>{decodeHTMLEntities(seo?.metaTitle)}</title>
      ) : (
        <title>Trootech</title>
      )}

      <link rel="icon" href="/favicon.png" />

      {type == "template" && (
        <>
          <meta name="robots" content="noindex, nofollow" />
          <meta name="googlebot" content="noindex, nofollow" />
        </>
      )}

      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary_large_image" />
      {seo?.canonicalURL ? (
        <link rel="canonical" href={seo.canonicalURL} />
      ) : (
        <link
          rel="canonical"
          href={`https://www.trootech.com${canonicalURL}`}
        />
      )}

      {seo?.structuredData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={formatJson(seo.structuredData)}
        />
      )}

      {!!seo?.metaImage?.data?.attributes.url ? (
        <>
          <meta
            property="twitter:image"
            content={`${resourceUrl}${seo?.metaImage?.data?.attributes.url}`}
          />
          <meta
            property="og:image"
            content={`${resourceUrl}${seo?.metaImage?.data?.attributes.url}`}
          />
        </>
      ) : (
        <>
          <meta
            property="twitter:image"
            content={`${resourceUrl}/images/og-image.jpg`}
          />
          <meta
            property="og:image"
            content={`${resourceUrl}/images/og-image.jpg`}
          />
        </>
      )}

      {commonMeta?.map((ele) => {
        if (!!seo?.[ele.content]) {
          return (
            <meta key={ele.name} name={ele.name} content={seo[ele.content]} />
          );
        }
        return null;
      })}

      {children ? children : <></>}
    </Head>
  );
};

export default HocHead;
